window.bootstrap = require('./bootstrap');
window.$ = window.jQuery = require('jquery');

function preventBehavior(e) {
    e.preventDefault();
}

document.addEventListener("touchmove", preventBehavior, {passive: false});

$('.scroll-available').on('touchmove', function(event){
    event.stopPropagation();
});
